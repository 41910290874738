<template>
  <div>
    <div class="ml-auto d-flex" v-if="!isDesktop">
      <template v-if="!isChange && contents.isEditable">
        <div class="mr-3">
          <a-button icon="edit" @click.prevent="showEdit">Edit</a-button>
        </div>
        <div>
          <a-button icon="delete" type="danger">Delete</a-button>
        </div>
      </template>
    </div>
    <div class="d-flex mb-3 align-items-center" :class="isChange ? null : 'mt-4'">
      <div class="mr-4 w-100" style="max-width: 600px;">
        <div class="title-detail" v-if="!isChange">{{contents.judul || '-'}}</div>
        <div v-else>
          <a-form-item label="Title" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
          <a-input
            placeholder="Insert your title..."
            v-model="newData.judul"
            size="large"
          />
        </div>
      </div>
      <div class="ml-auto d-flex" v-if="isDesktop">
        <template v-if="!isChange && contents.isEditable">
          <div class="mr-3">
            <a-button icon="edit" @click.prevent="showEdit">Edit</a-button>
          </div>
          <div>
            <a-button icon="delete" type="danger" :loading="loadingDeleteAnnouncement" @click.prevent="handleDeleteAnnouncement()">Delete</a-button>
          </div>
        </template>
      </div>
    </div>
    <div class="meta" v-if="!isChange">
      <a-row type="flex" align="middle">
        <a-col :md="3" :sm="24"><p class="meta-title">Created By</p></a-col>
        <a-col :md="5" :sm="24"><p class="meta-content">{{contents.guru ? contents.guru.nama : 'Admin'}}</p></a-col>
      </a-row>
      <a-row class="mt-2" type="flex" align="middle">
        <a-col :md="3" :sm="24"><p class="meta-title">Created At</p></a-col>
        <a-col :md="5" :sm="24"><p class="meta-content">{{contents.firstCreated || '-'}}</p></a-col>
      </a-row>
      <a-row class="mt-2" type="flex" align="middle">
        <a-col :md="3" :sm="24"><p class="meta-title">Last Modified</p></a-col>
        <a-col :md="5" :sm="24"><p class="meta-content">{{contents.lastModified || '-'}}</p></a-col>
      </a-row>
      <a-row class="mt-2" type="flex" align="middle">
        <a-col :md="3" :sm="24"><p class="meta-title">Recipient</p></a-col>
        <a-col :md="5" :sm="24" v-if="contents.tag"><a-tag class="my-1" v-for="(tag, j) in contents.tag" :key="j" :color="tag.color">{{tag.title}}</a-tag></a-col>
      </a-row>
    </div>
    <div v-else>
      <a-row :gutter="[16,16]">
        <a-col :lg="4">
          <a-form-item label="Recipient" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
          <a-select
            v-model="newData.type"
            size="large"
            class="w-100"
            style="min-width: 150px;"
            @change="handleChange('type')"
          >
            <a-select-option value="general">General</a-select-option>
            <a-select-option v-if="levels.length" value="id_level">By Level</a-select-option>
            <a-select-option v-if="classes.length" value="id_kelas">By Class</a-select-option>
          </a-select>
        </a-col>
        <a-col :lg="6">
          <template v-if="newData.type === 'id_level'">
            <a-form-item label="Level" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
            <a-select
              mode="multiple"
              v-model="newData.id_level"
              size="large"
              class="w-100"
              style="min-width: 150px;"
            >
              <a-select-option v-for="level in levels" :key="level.id" :value="level.id">{{level.nama}}</a-select-option>
            </a-select>
          </template>
          <template v-else-if="newData.type === 'id_kelas'">
            <a-form-item label="Classes" class="mb-0 hsn-input-required" :colon="false"></a-form-item>
            <a-select
              mode="multiple"
              v-model="newData.id_kelas"
              size="large"
              class="w-100"
              style="min-width: 150px;"
            >
              <a-select-option v-for="kelas in classes" :key="kelas.id" :value="kelas.id">{{kelas.title}}</a-select-option>
            </a-select>
          </template>
        </a-col>
      </a-row>
    </div>
    <div class="my-4" v-if="!isChange">
      <div v-if="contents.gambar_utama_filename">
        <img style="width: 100%; max-height: 300px;" :src="`${config.apiUrl}/pengumuman/${contents.gambar_utama_filename}`">
      </div>
      <div v-html="contents.deskripsi">
      </div>
    </div>
    <div class="my-4" v-else>
      <a-form-item label="Description" class="mb-0" :colon="false"></a-form-item>
      <ckeditor
        v-model="newData.deskripsi"
        :editor="editor"
        :config="editorConfig"
      ></ckeditor>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingDeleteAnnouncement: false,
    }
  },
  props: {
    newData: {
      type: Object,
    },
    classes: {
      type: Array,
    },
    levels: {
      type: Array,
    },
    contents: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
    isCreate: {
      type: Boolean,
    },
  },
  methods: {
    handleChange(col) {
      this.$emit('handle-change', col)
    },
    showEdit() {
      this.$emit('showEdit')
    },
    handleDeleteAnnouncement() {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this announcement?</div>
        ),
        onOk: async () => {
          try {
            this.loadingDeleteAnnouncement = true
            await this.$store.dispatch('announcement/DELETE_ANNOUNCEMENT', {
              idAnnouncement: this.contents.id,
            })
            this.$emit('afterDelete')
            this.$notification.success({
              message: 'Success',
              description:
                'Announcement has been deleted',
            })
            this.loadingDeleteAnnouncement = false
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: 'Announcement has not been deleted',
            })
          }
        },
        onCancel: () => {
          this.loadingDeleteAnnouncement = false
          this.visibleDetail = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
  },
  computed: {
    isChange() {
      return this.isEdit || this.isCreate
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      const config = this.$store.state.ckEditor.editorConfig
      const items = config.toolbar.items.filter(item => item !== 'MathType')
      return {
        ...config,
        toolbar: {
          items: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            ...items,
          ],
          shouldNotGroupWhenFull: true,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.modal-card-announcement {
  .title-detail {
    font-size: 20px;
    font-weight: 600;
    color: #303030
  }

  .meta {
      .meta-title {
        font-size: 13px!important;
        color: #9c9c9c;
        letter-spacing: 0.5px;
      }

      .meta-content {
        font-size: 13px!important;
        color: #2e2e2e;
      }
    }

    .ck-editor__editable {
      min-height: 300px;
    }
}
</style>
